
import { ElMessageBox } from "element-plus";
import { defineComponent } from "vue";
import _ from "lodash";
import helpers from "@/helpers/global";
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);

export default defineComponent({
  name: "WorkStyleIdentifier",
  inheritAttrs: false,
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    // workStyleScenarios: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.checkingNextStep();
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    companyLogoUrl(): string {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime() {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    companyColor(): string {
      const defaultCompanyColor = "#000000";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    workStyleScenarios(): any {
      return this.$store.getters["assessmentSection/workStyleIdentifier"];
    },
    progressShowing() {
      const step = this.step;
      const array1 = ["Work_Style_Identifier"];

      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    timeSection(): string {
      return this.$store.getters["routes/timeSection"];
    },
    isLightColor() {
      const user = this.$store.getters["routes/user"] || {};
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
  },
  props: ["lastSection"],
  data() {
    return {
      step: "introduce_Work_Style", // introduce_Work_Style
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      openedQuestion: "",
      saved: false,
      canNextStep: false,
      percentageLabel: 0,
      scenarioIndexShowing: 1,
      timer: 0,
      timerLabel: "0:00",
    };
  },
  mounted() {
    this.countDownTimeSession();
    const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
    this.hasOpenEndedQuestion = formTemplate.open_ended_question || false;
  },
  methods: {
    getStarted() {
      this.countDownTime();
      this.clearIntervalSession();
      this.step = "Work_Style_Identifier";
    },
    optionCls(option: { value: any }) {
      // const colors = [
      //   "color-15CE95",
      //   "color-FAA13A",
      //   "color-FA8B3A",
      //   "color-FA5F3A",
      //   "color-FA3A3A",
      // ];
      let cls = "";
      const index = parseInt(option.value);
      // return colors[index] ? colors[index] : "";
      if (index) {
        cls = "";
      }

      return cls;
    },
    onClickOption(scenario: any, question: any, option: any) {
      const scenarios = this.workStyleScenarios.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].id != scenario.id) continue;
        for (const j in scenarios[i].questions) {
          const _question = scenarios[i].questions[j];
          if (_question.id != question.id) continue;
          for (const k in scenarios[i].questions[j].options) {
            const o = scenarios[i].questions[j].options[k];
            o.checked = o.code == option.code;
            if (o.checked) {
              this.canNextStep = true;
            }
          }
        }
      }
    },
    checkingNextStep() {
      const order = this.scenarioIndexShowing;
      const nextOrder = order + 1;
      let found = false;
      for (const i in this.workStyleScenarios.scenarios) {
        if (found) break;
        const scenario = this.workStyleScenarios.scenarios[i];
        found = scenario.order == nextOrder;
      }
      this.canNextStep = found;
    },
    completeAssessment() {
      // this.step = "opened_question";
      this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
    },
    makeFormValues() {
      let values: any = {};
      const name = this.workStyleScenarios.name;
      values[name] = {};
      const scenarios = this.workStyleScenarios.scenarios;
      for (const i in scenarios) {
        const group = _.snakeCase(this.workStyleScenarios.scenarios[i].name);
        values[name][group] = {};
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              const answer = {
                id: question.id,
                // value: parseInt(question.options[k].value),
                code: question.options[k].code,
              };
              values[name][group][answer.id] = answer.code;
            }
          }
        }
      }
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.work_style_identifier_feedback = feedback.trim();
      }
      return values;
    },
    async finishAssessment() {
      const values = this.saved ? null : this.makeFormValues();
      await this.$store.dispatch("assessment/finish", values, { root: true });
      this.saved = true;
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          "https://myculture.happily.ai/thank-you-for-submitting";
        window.open(redirectUrl, "_self");
      }
    },
    countDownTime() {
      this.percentageLabel = 5 * 60 + this.extratime * 60;
      this.timer = 5 * 60 + this.extratime * 60;
      let interval = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(interval);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(interval);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      this.timer = 5 * 60;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      this.scenarioIndexShowing = index - 1;
    },
    setupNexStep() {
      if (
        this.scenarioIndexShowing ==
        Object.keys(this.workStyleScenarios.scenarios).length
      ) {
        this.completeAssessment();
        this.saveTimeSection();
      } else if (this.canNextStep) {
        this.checkingNextStep();
        this.scenarioIndexShowing++;
      }
    },
    finishOpenedQuestion() {
      this.step = "complete";
    },
    nextSectionBTN() {
      this.$emit("finishValueAndTraits");
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionWork = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionWork },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionWork = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionWork },
        { root: true }
      );
    },
  },
});
