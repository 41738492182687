
import { defineComponent } from "vue";
import ConfirmCandidate from "@/views/ConfirmCandidate.vue";
import ValuesAndTraits from "@/views/AssessmentsTest/ValuesAndTraits.vue";
import AcceptableBehaviors from "@/views/AssessmentsTest/AcceptableBehaviors.vue";
import OaicArchetypes from "@/views/AssessmentsTest/OAICArchetypes.vue";
import WorkStyleIdentifier from "@/views/AssessmentsTest/WorkStyleIdentifier.vue";
import HumanSkills from "@/views/AssessmentsTest/HumanSkills.vue";
// import { ElLoading } from "element-plus";
// let loadingEl: any;

export default defineComponent({
  name: "AssessmentsContainerTest",
  title() {
    let name = "";
    if (this.formTemplate) {
      let newName = this.formTemplate.form_name;
      if (this.formTemplate.form_name && newName.trim()) {
        name = newName.trim() + " | ";
      }
    }
    return `${name}My Culture`;
  },
  components: {
    ConfirmCandidate,
    ValuesAndTraits,
    AcceptableBehaviors,
    OaicArchetypes,
    WorkStyleIdentifier,
    HumanSkills,
  },
  async created() {
    const code = this.$router.currentRoute.value.params.code || "";
    if (!code) {
      this.$router.replace(`/404`);
      return;
    }
    await this.$store.dispatch("routes/getRoute", code, { root: true });
    if (this.step == "get_start") {
      if (!this.initialized) {
        this.initialize();
      }
    }
    this.lang = (this as any).$i18n.getLocale(0);
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    error(value) {
      console.error(value);
    },
    // loading(value) {
    //   if (value) {
    //     loadingEl = ElLoading.service({
    //       lock: true,
    //       text: "Login..",
    //       background: "#ffffff90",
    //     });
    //   } else {
    //     if (loadingEl) {
    //       loadingEl.close();
    //     }
    //   }
    // },
  },
  computed: {
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      const sections = formTemplate.sections || [];
      return extratime * sections.length;
    },
    lastSection() {
      const status =
        (!this.section.values_and_traits ||
          this.completeSection.values_and_traits) &&
        (!this.section.OAIC_archetypes ||
          this.completeSection.OAIC_archetypes) &&
        (!this.section.human_skills || this.completeSection.human_skills) &&
        (!this.section.acceptable_behaviors ||
          this.completeSection.acceptable_behaviors) &&
        (!this.section.work_style_identifier ||
          this.completeSection.work_style_identifier);
      if (status) {
        return true;
      }
      return false;
    },
    error(): any {
      return this.$store.getters["assessment/error"];
    },
    loading(): any {
      return this.$store.getters["assessment/loading"];
    },
    candidate(): any {
      return this.$store.getters["assessment/candidate"];
    },
    invitation(): any {
      return this.$store.getters["assessment/invitation"];
    },
    formTemplate(): any {
      return this.$store.getters["assessment/formTemplate"];
    },
    valuesAndTraits(): any {
      const valuesAndTraits =
        this.$store.getters["assessmentSection/valuesAndTraits"];
      const formTemplate = this.$store.getters["assessment/formTemplate"];

      const isCustomFields = formTemplate
        ? formTemplate.values_and_traits_is_custom
        : false;

      const defaultQuestion = {
        checked: false,
        dirty: false,
        id: "",
        name: "",
        option: [
          { value: 1, label: "1" },
          { value: 2, label: "2" },
          { value: 3, label: "3" },
          { value: 4, label: "4" },
          { value: 5, label: "5" },
        ],
        order: 9999,
        title: "",
        title_th: "",
        type: "choice ",
        value: "",
      };

      const sortByName = (a: any, b: any) => {
        const nameA = a.title.toUpperCase(); // ignore upper and lowercase
        const nameB = b.title.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      };

      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.definingYourValues &&
        valuesAndTraits.scenarios.definingYourValues.questions
      ) {
        if (isCustomFields) {
          const customFields = formTemplate.values_and_traits_custom_fields;
          const valuesIncludedOptions = customFields.values_included_options;

          const newQuestions = [];
          const questionsMap: any = {};
          for (const i in valuesAndTraits.scenarios.definingYourValues
            .questions) {
            const question =
              valuesAndTraits.scenarios.definingYourValues.questions[i];
            questionsMap[question.id] = question;
          }

          for (const i in valuesIncludedOptions) {
            const valuesIncludedOption = valuesIncludedOptions[i];
            const key = valuesIncludedOption.key;
            if (key.indexOf("custom_") === 0) {
              const customQuestion = {
                ...defaultQuestion,
                ...{
                  id: key,
                  name: valuesIncludedOption.labelEN,
                  title: valuesIncludedOption.labelEN,
                  title_th: valuesIncludedOption.labelTH,
                },
              };
              newQuestions.push(customQuestion);
            } else {
              if (Object.prototype.hasOwnProperty.call(questionsMap, key)) {
                newQuestions.push(questionsMap[key]);
              }
            }
          }
          valuesAndTraits.scenarios.definingYourValues.questions = newQuestions;
        }

        // helpers.shuffleArray(
        //   valuesAndTraits.scenarios.definingYourValues.questions
        // );

        // @see https://trello.com/c/c3OAjDA8/1339-assessment-as-a-company-i-want-to-be-able-to-configure-my-values-traits-from-a-preset-list-so-that-i-can-easily-identify-items-t
        valuesAndTraits.scenarios.definingYourValues.questions.sort(sortByName);
      }

      if (
        valuesAndTraits &&
        valuesAndTraits.scenarios &&
        valuesAndTraits.scenarios.identifyingKeyTraits &&
        valuesAndTraits.scenarios.identifyingKeyTraits.questions
      ) {
        if (isCustomFields) {
          const customFields = formTemplate.values_and_traits_custom_fields;
          const traitsIncludedOptions = customFields.traits_included_options;

          const newQuestions = [];
          const questionsMap: any = {};
          for (const i in valuesAndTraits.scenarios.identifyingKeyTraits
            .questions) {
            const question =
              valuesAndTraits.scenarios.identifyingKeyTraits.questions[i];
            questionsMap[question.id] = question;
          }

          for (const i in traitsIncludedOptions) {
            const traitsIncludedOption = traitsIncludedOptions[i];
            const key = traitsIncludedOption.key;
            if (key.indexOf("custom_") === 0) {
              const customQuestion = {
                ...defaultQuestion,
                ...{
                  id: key,
                  name: traitsIncludedOption.labelEN,
                  title: traitsIncludedOption.labelEN,
                  title_th: traitsIncludedOption.labelTH,
                },
              };
              newQuestions.push(customQuestion);
            } else {
              if (Object.prototype.hasOwnProperty.call(questionsMap, key)) {
                newQuestions.push(questionsMap[key]);
              }
            }
          }
          valuesAndTraits.scenarios.identifyingKeyTraits.questions =
            newQuestions;
        }

        valuesAndTraits.scenarios.identifyingKeyTraits.questions.sort(
          sortByName
        );
      }

      return valuesAndTraits;
    },
    greeting(): string {
      const candidate = this.$store.getters["assessment/candidate"];
      if (!candidate || !candidate.firstname) return "";
      return `${(this as any).$t("assessmentTest.Hello")} ${
        candidate.firstname
      },`;
    },
    creator(): string {
      return this.$store.getters["assessment/creator"];
    },
    timeSection(): number {
      const data = this.$store.getters["routes/data"];
      const sections = data.data.sections || data.data.section || [];
      const timeValue = sections.length;
      let timeSection = timeValue * 5;

      if (sections.indexOf("human_skills") !== -1) {
        timeSection += 5;
      }
      return timeSection;
    },
    section(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const originalSection = formTemplate.sections || [];
      const hasSection = (name: string) => {
        return originalSection.indexOf(name) !== -1;
      };
      return {
        values_and_traits: hasSection("values_and_traits"),
        OAIC_archetypes: hasSection("OAIC_archetypes"),
        human_skills: hasSection("human_skills"),
        acceptable_behaviors: hasSection("acceptable_behaviors"),
        work_style_identifier: hasSection("work_style_identifier"),
      };
    },
  },
  data() {
    return {
      step: "confirm_candidate", // get_start, confirm_candidate
      // values_and_traits, OAIC_archetypes, acceptable_behaviors, work_style_identifier, human_skills
      initialized: false,
      lang: "en",
      newAssessment: "",
      // section: {
      //   values_and_traits: true,
      //   OAIC_archetypes: true,
      //   acceptable_behaviors: true,
      // },
      // Negative with section!
      completeSection: {
        values_and_traits: false,
        OAIC_archetypes: false,
        human_skills: false,
        acceptable_behaviors: false,
        work_style_identifier: false,
      },
    };
  },
  methods: {
    async initialize(data?: any) {
      // @see src\views\Assessments\AssessmentsDetails.vue
      try {
        const routeData = this.$store.getters["routes/data"] || {};
        const path = routeData.path || "";
        const isSecured = path == "/assessment-test/:id/:params";
        let id = "";
        let uuid = "";
        const parmas = new URLSearchParams();
        if (isSecured) {
          const data = routeData.data;
          id = routeData.uuid.split("-")[0];
          parmas.set("email", data.email);
          parmas.set("firstname", data.firstname);
          parmas.set("lastname", data.lastname);
          uuid = btoa(encodeURIComponent(parmas.toString()));
        } else {
          id = routeData.uuid;
          parmas.set("email", data.email);
          parmas.set("firstname", data.firstname);
          parmas.set("lastname", data.lastname);
          uuid = btoa(encodeURIComponent(parmas.toString()));
        }

        await this.$store.dispatch(
          "assessment/load",
          {
            id: id,
            uuid: uuid,
            isSecured: isSecured,
            lang: data.lang,
            sendFreeStuff: data.sendFreeStuff,
          },
          { root: true }
        );
        this.initialized = true;
      } catch (e) {
        console.error(e);
      }
    },
    reTurnValueAndTraits(values: { values_and_traits: any }) {
      // @todo save to invitation for observe progressing
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });

      this.completeSection.values_and_traits = true;
      this.nextStepBTN();
    },
    finishAssessment() {
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          "https://myculture.happily.ai/thank-you-for-submitting";
        window.open(redirectUrl, "_self");
      }
    },
    // reTurn -> return (typo style)
    reTurnHumanSkills(values: { human_skills: any }) {
      // console.log("reTurnHumanSkills()");
      // console.log(values, "values");
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.human_skills = true;
      this.nextStepBTN();
    },
    reTurnAcceptableBehaviors(values: { acceptable_behaviors: any }) {
      // console.log("reTurnAcceptableBehaviors()");
      // console.log(values, "values");
      this.$store.dispatch("assessment/saveFormDetails", values, {
        root: true,
      });
      this.completeSection.acceptable_behaviors = true;
      this.nextStepBTN();
    },
    reTurnWorkStyleIdentifier() {
      this.completeSection.work_style_identifier = true;
      this.nextStepBTN();
    },
    finishConfirmCandidate(data: any) {
      if (data) {
        this.step = "get_start";
        if (!this.initialized) {
          this.initialize(data);
        }
      }
    },
    async nextStepBTN() {
      // console.log("nextStepBTN()");
      const section = this.section;
      const completeSection = this.completeSection;
      // console.log(section, "section");
      // console.log(completeSection, "completeSection");
      for (const key in section) {
        let selctedAssessment =
          section[key as keyof typeof section] === true &&
          completeSection[key as keyof typeof completeSection] === false;
        if (key == "values_and_traits" && selctedAssessment) {
          this.step = "values_and_traits";
          this.completeSection.values_and_traits = true;
          break;
        } else if (key == "OAIC_archetypes" && selctedAssessment) {
          this.step = "OAIC_archetypes";
          this.completeSection.OAIC_archetypes = true;
          break;
        } else if (key == "human_skills" && selctedAssessment) {
          this.step = "human_skills";
          this.completeSection.human_skills = true;
          break;
        } else if (key == "acceptable_behaviors" && selctedAssessment) {
          this.step = "acceptable_behaviors";
          this.completeSection.acceptable_behaviors = true;
          break;
        } else if (key == "work_style_identifier" && selctedAssessment) {
          this.step = "work_style_identifier";
          this.completeSection.work_style_identifier = true;
          break;
        }
      }

      // mark status from invited to todo
      const routeData = this.$store.getters["routes/data"];
      const path = routeData.path || "";
      const isSecured = path == "/assessment-test/:id/:params";
      let uuid = routeData.uuid;
      if (!isSecured) {
        uuid = this.$store.getters["assessmentTemplate/publicInvitedUuid"];
      }
      const formDetails = {
        _id: uuid,
      };
      await this.$store.dispatch("assessment/start", formDetails, {
        root: true,
      });
    },
  },
});
