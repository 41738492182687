
import { defineComponent } from "vue";
import { ElMessageBox } from "element-plus";
import _ from "lodash";
import helpers from "@/helpers/global";
// import { ElLoading } from "element-plus";

const DEFAULT_MINIMUM_CHOICES = 8;
const MINIMUM_VALUES_CHOICES = DEFAULT_MINIMUM_CHOICES;
const MINIMUM_TRAITS_CHOICES = 10;
// const MINIMUM_VALUES_CHOICES = 1;
// const MINIMUM_TRAITS_CHOICES = 1;
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);

export default defineComponent({
  name: "ValuesAndTritesTest",
  inheritAttrs: false,
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    progressShowing() {
      const step = this.step;
      const array1 = ["select_ten_value", "select_ten_traits"];
      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    MINIMUM_VALUES_CHOICES(): number {
      const length =
        this.valuesAndTraits.scenarios.definingYourValues.questions.length;
      if (length > MINIMUM_VALUES_CHOICES) return MINIMUM_VALUES_CHOICES;
      return Math.max(Math.floor(length / 2), 1);
    },
    MINIMUM_TRAITS_CHOICES(): number {
      const length =
        this.valuesAndTraits.scenarios.identifyingKeyTraits.questions.length;
      if (length > MINIMUM_TRAITS_CHOICES) return MINIMUM_TRAITS_CHOICES;
      return Math.max(Math.floor(length / 2), 1);
    },
    timeSection(): any {
      return this.$store.getters["routes/timeSection"];
    },
    companyColor(): string {
      const defaultCompanyColor = "#000000";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    isLightColor() {
      const user = this.$store.getters["routes/user"];
      const color = user.color || "#000";
      return helpers.isLightColor(color);
    },
  },
  props: ["lastSection", "valuesAndTraits"],
  data() {
    return {
      step: "introduce_values_and_traits", // introduce_values_and_traits
      openedQuestion: "",
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      selectValueData: [
        {
          id: "Articulate",
          label: "Articulate",
        },
      ],
      selectedValues: [""],
      selectTraitsData: [
        {
          id: "Articulate",
          label: "Articulate",
        },
      ],
      selectedTraits: [""],
      timer: 0,
      timerLabel: "0:00",
      percentageLabel: 0,
    };
  },
  mounted() {
    this.countDownTimeSession();
    this.selectedValues = [];
    this.selectedTraits = [];
    this.mockData();

    const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
    this.hasOpenEndedQuestion = formTemplate.open_ended_question || false;
  },
  methods: {
    async finishAssessment() {
      const formValues = this.makeFormValues();
      await this.$store.dispatch("assessment/finish", formValues, {
        root: true,
      });
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          "https://myculture.happily.ai/thank-you-for-submitting";
        window.open(redirectUrl, "_self");
      }
    },
    mockData() {
      // mock value data
      for (let i = 0; i < 20; i++) {
        this.selectValueData.push({
          id: "Articulate_" + i,
          label: "Articulate",
        });
      }
      // mock traits data
      for (let i = 0; i < 20; i++) {
        this.selectTraitsData.push({
          id: "Articulate_" + i,
          label: "Articulate",
        });
      }
    },
    countDownTime() {
      this.percentageLabel = 5 * 60 + this.extratime * 60;
      this.timer = 5 * 60 + this.extratime * 60;
      let interval = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(interval);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(interval);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      this.timer = 5 * 60;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    styleSelectedTraits(key: string) {
      const found = this.selectedTraits.find((element: any) => element == key);
      if (found) {
        return true;
      }
      return false;
    },
    selectedTenTraits(value: { id: string }) {
      const found = this.selectedTraits.find(
        (element: any) => element == value.id
      );
      if (found) {
        var index = this.selectedTraits.indexOf(found);
        if (index !== -1) {
          this.selectedTraits.splice(index, 1);
        }
      } else {
        if (this.selectedTraits.length < this.MINIMUM_TRAITS_CHOICES) {
          this.selectedTraits.push(value.id);
        }
      }
    },
    styleSelectedValue(key: string) {
      const found = this.selectedValues.find((element: any) => element == key);
      if (found) {
        return true;
      }
      return false;
    },
    selectedTenValue(value: { id: string }) {
      const found = this.selectedValues.find(
        (element: any) => element == value.id
      );
      if (found) {
        var index = this.selectedValues.indexOf(found);
        if (index !== -1) {
          this.selectedValues.splice(index, 1);
        }
      } else {
        if (this.selectedValues.length < this.MINIMUM_VALUES_CHOICES) {
          this.selectedValues.push(value.id);
        }
      }
    },
    finishValue() {
      if (this.selectedValues.length == this.MINIMUM_VALUES_CHOICES) {
        this.step = "select_ten_traits";
      }
    },
    finishTraits() {
      if (this.selectedTraits.length == this.MINIMUM_TRAITS_CHOICES) {
        this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
        this.saveTimeSection();
      }
    },
    finishOpenedQuestion() {
      this.step = "complete";
    },
    makeFormValues() {
      let values: any = {
        values_and_traits: {
          defining_your_values: _.compact(this.selectedValues),
          identifying_key_traits: _.compact(this.selectedTraits),
        },
      };
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.values_and_traits_feedback = feedback.trim();
      }
      return values;
    },
    nextSectionBTN() {
      const formValues = this.makeFormValues();
      this.$emit("finishValueAndTraits", formValues);
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionValue = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionValue },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionValue = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionValue },
        { root: true }
      );
    },
  },
});
